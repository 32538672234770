import store from '@/store'
import { submit } from './projectActions'
import { errorModal } from '@/modalMessages'
import { REGIONS } from '@/forms/selectOptions'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'text',
        name: 'name',
        label: 'Naam',
        placeholder: 'Naam',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'checkbox',
        name: 'is_archived',
        label: 'Gearchiveerd',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'street',
        name: 'street',
        label: 'Straat',
        placeholder: 'Zoek een straat',
        outerClass: 'tw-m-0',
        '@callback': 'streetSelected'
      },
      {
        type: 'text',
        name: 'number',
        label: 'Nummer',
        placeholder: 'Nummer',
        outerClass: 'tw-m-0'
      },
      {
        type: 'text',
        name: 'box',
        label: 'Bus',
        placeholder: 'Bus',
        validation: 'max:10,length',
        outerClass: 'tw-m-0'
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'city',
        name: 'city',
        label: 'Stad',
        placeholder: 'Zoek op postcode of plaatsnaam',
        validation: 'required',
        showRequiredLabel: true,
        outerClass: 'tw-m-0'
      },
      {
        name: 'region',
        label: 'Gewest',
        placeholder: 'Selecteer regio',
        type: 'select',
        options: REGIONS,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'publish_address',
        label: 'Adres zichtbaarheid',
        placeholder: 'Selecteer adres zichtbaarheid',
        validation: 'required',
        showRequiredLabel: true,
        options: {
          0: 'Publiceer adres niet',
          1: 'Publiceer adres zonder huisnummer',
          2: 'Publiceer adres'
        },
        outerClass: 'tw-m-0'
      }
    ]
  }
]

const actions = {
  async load (projectId) {
    try {
      // Project data is already loaded by the layout, no need to call API again.
      const project = store.getters['properties/getProjectById'](projectId)
      const { logo, architect, niche, promoter, ...payload } = { ...project }

      // We don't update logo, architect, niche and promoter in this form, hence not including it below, as they have validations sometimes.
      return payload
    } catch (error) {
      errorModal('Fout bij het laden van projectdetails, probeer het opnieuw.')
      throw error
    }
  },
  submit
}

export default {
  heading: 'Algemene gegevens',
  schema,
  actions
}
